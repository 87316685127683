<template>
  <div id="app">
    <!-- 其他页 -->
    <el-container v-if="$route.meta.keepAlive">
      <el-header>
        <el-image src="https://kuaipii.com/dataimg/logo.png" style="float:left;height: 60px;width: 60px;"></el-image>
        <el-text  style="float:left;font-size: 50px;margin-left:20px;color: rgb(0, 46, 250);font-family: KaiTi;"> 快匹科技</el-text>
        <!-- 导航栏 -->
        <keep-alive>
          <top></top>
        </keep-alive>
      </el-header>
      <el-container>
        <!-- <el-aside width="sideWidth+'px'">
          <keep-alive>
            <left></left>
          </keep-alive>
        </el-aside> -->
        <el-main style="padding-top: 3px;">
          <!-- Body -->
            <router-view v-slot="{ Component, route }">
              <transition :name="route.meta.transition">
                <component :is="Component" />
              </transition>
            </router-view>
        </el-main>
      </el-container>
      <el-text class="mx-1" type="info" style="position: relative;bottom: 10px;">粤ICP备2022121970号-3</el-text>
    </el-container>

    <!-- 登录页 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import left from '@/layout/left.vue';
import top from '@/layout/top.vue'
import { ref } from 'vue';
export default {
  components: {
    left: left,
    top: top
  },
  setup() {
    const sideWidth = ref(180)

    return {

    }
  }
}

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F8F8F8;
}

body {
  margin: 0;
  padding: 0;
}

top {
  width: 100%;
}
</style>
