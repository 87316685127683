<template>
  <div>
    <transition>
      <div id="front-breadcrumb"
        v-bind:class="{ id01: store.state.sidebarIsCollaspe, id02: !store.state.sidebarIsCollaspe }">
        <div class="front-user">
          <span class="user-role" style="color: var(--el-text-color-regular);font-size: large;">
            {{ userInfo.userRoleName }}
          </span>
          admin
          <el-dropdown size="small" trigger="hover">
            <span class="user-name">
              {{ userInfo.userName }}
              <el-icon class="el-icon-right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu class="user-control">
                <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>

    </transition>
  </div>
</template>


<script setup>
import { ArrowDown } from '@element-plus/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { reactive } from 'vue';
import Cookies from 'js-cookie';
import { ElMessageBox, ElMessage } from 'element-plus';



const store = useStore()
const router = useRouter();

const userInfo = reactive({
  userName: '',
  userCompany: '',
  userRole: '',
  userRoleName: '',
})

onMounted(() => {

})


const handleLogout = () => {
  ElMessageBox.confirm(
    '确认要退出该账号登陆吗?',
    'Warning',
    {
      confirmButtonText: '是',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      Cookies.remove('kp_token')
      Cookies.set('isLogin', false, Boolean)
      store.commit('resetParam', store.state)
      router.push('/login')
      ElMessage({
        type: 'success',
        message: '退出成功',
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消',
      })
    })

}

</script>

<style rel="stylesheet/scss" lang="scss">
#front-breadcrumb {
  display: flex;
  height: 30px;
  border-bottom: solid;
  .sidebarTrigger {
    margin-top: 10px;
  }

  .breadcrumb {
    margin-top: 11px;
  }

  transition: margin-left 0.5s ease-in-out;
}

//变换样式动画
#front-breadcrumb--expanded {
  margin-left: 200px;
}

#front-breadcrumb--collapsed {
  margin-left: 60;
}

//变换样式
.id01 {
  margin-left: 60px;
}

.id02 {
  margin-left: 160px;
}

.front-user {
  margin-left: auto;
  font-size: small;
  display: flex;
  align-items: flex-end;

  .user-role {
    margin-right: 10px;
  }

  .user-name {
    margin-bottom: 3px;
  }
}

.tagsView {
  margin-top: 2px;
}

.el-dropdown {
  margin-top: 1rem;
  font-size: larger;

}
</style>
