<template>
  <el-menu router :default-active="route.path" default-active="2" class="menu" :collapse="store.state.sidebarIsCollaspe"
    @open="" @close="">
    <el-menu-item index="/user">
      <el-icon>
        <Document />
      </el-icon>用户管理</el-menu-item>
    <el-menu-item index="/good"> <el-icon>
        <Star />
      </el-icon>货品管理</el-menu-item>
  </el-menu>
</template>

<script setup>

import { useRoute, useRouter } from "vue-router"
import { ref, watch } from 'vue'
import { Document, Setting, Star, User } from '@element-plus/icons-vue'
import { useStore } from 'vuex';
import { reactive } from 'vue';
const route = useRoute()
const router = useRouter()
const store = useStore()



// 监听函数监听路径的变化
watch(
  () => route.path,
  (newValue, oldValue) => {
    // route.matched 目前匹配的是哪个 获得的值赋给  navArray.valu 上面用 v-for遍历值
    const tag = reactive({
      path: newValue,
      title: route.meta.title
    })
    store.commit('addTag', (store.state, tag))
  }
);
</script>

<style rel="stylesheet/scss" lang="scss">
.menu {
  width: 160px;
  min-height: 900px;
}
</style>
