import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '@/utils/auth';

// 公共路由
export const routes = [
  {
    path: "/",
    redirect: "/good",
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/home',
    component: () => import('@/views/home/index.vue'),
    name: "home",
    meta: {
      keepAlive: true,
      title: "首页"
    }
  },
  {
    path: '/user',
    component: () => import('@/views/user/index.vue'),
    name: "用户管理",
    meta: {
      keepAlive: true,
      title: "用户管理"
    }
  },
  {
    path: '/good',
    component: () => import('@/views/good/index.vue'),
    name: "货品管理",
    meta: {
      keepAlive: true,
      title: "货品管理"
    }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
    meta: {
      keepAlive: false,
      title: "登陆"
    }
  },
]
export const constantRoutes = createRouter({
  history: createWebHistory(),
  routes
})

// 全局前置守卫
constantRoutes.beforeEach((to, from, next) => {
  // 检查用户是否已经登录
  // if (to.name !== 'login' && !isAuthenticated()) {
  //   next({ name: 'login' }); // 未登录，跳转到登录页面
  // } else {
  //   next(); // 已登录，继续导航
  // }
  next();
});
export default constantRoutes;

