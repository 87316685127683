import Cookies from 'js-cookie'

const TokenKey = 'kp_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
// 检查用户是否已经登录
export function Authenticate() {
  // 从cookie或其他方式获取登录状态
  const isLoggedIn = Cookies.set('isLoggedIn','true'); // 假设使用Cookie来存储登录状态
}
// 检查用户是否已经登录
export function isAuthenticated() {
  // 从cookie或其他方式获取登录状态
  const isLoggedIn = Cookies.get('isLoggedIn'); // 假设使用Cookie来存储登录状态

  return isLoggedIn === 'true'; // 返回登录状态，可以根据实际情况进行判断
}